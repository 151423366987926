import * as React from "react";

import PageLayout from "../../components/PageLayout";
import Header from "../../components/Header";
import TermsTitleSection from "../../sections/TermsAndConditions/TermsTitleSection";
import Footer from "../../components/Footer";
import AffiliateProgramContent from "../../components/AffiliateProgramContent";
import AffiliateStartSection from "../../sections/AffiliateStartSection";

const AffiliateProgram = () => {
  return (
    <>
      <Header />
      <PageLayout title="Affiliate program">
        <TermsTitleSection title="Affiliate program"/>
        <AffiliateProgramContent/>
        <AffiliateStartSection/>
        <Footer />
      </PageLayout>
    </>
  );
};

export default AffiliateProgram;
